
<template>
  <div class="page-box">
    <a-spin :spinning="loading">
      <div class="goods-detail-box" >
        <div class="goods-detail" v-if='!loading'>
          <div class="goods-info">
            <div class="img-info">
              <template v-if="productDetail.picList.length">
                <base-img v-if='productDetail.picList[picActive].type !== 1' :src="productDetail.picList[picActive].picPath" width="240" height="240" oss-style="240_240" />
                <video class='big-video' v-else controls :src="baseUrl + productDetail.picList[picActive].picPath">
                  当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + productDetail.picList[picActive].picPath" target="_blank">下载视频</a>
                </video>
                <swiper ref="swiperRef" class="img-swiper" :options="swiperOptions">
                  <swiper-slide v-for="(picItem, i) in productDetail.picList" :key="picItem.productPicId" :class="{active: i === picActive}" @click.native="picActive = i">
                    <base-img v-if='picItem.type !== 1' :src="picItem.picPath" width="46" height="46" oss-style="50_50" />
                    <video class='small-video' v-else :src="baseUrl + picItem.picPath">
                      当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + picItem.picPath" target="_blank">下载视频</a>
                    </video>
                  </swiper-slide>
                  <template v-if="productDetail.picList > 4">
                    <div class="swiper-btn btn-prev" slot="button-prev" @click="swiperControl('prev')">
                      <a-icon type="caret-left" />
                    </div>
                    <div class="swiper-btn btn-next" slot="button-next" @click="swiperControl('next')">
                      <a-icon type="caret-right" />
                    </div>
                  </template>
                </swiper>
              </template>
            </div>
            <div class="info-info">
              <h3 class="goods-name">{{productDetail.product.name}}</h3>
              <p class="sub-name" v-if="productDetail.product.subTitle">{{productDetail.product.subTitle}}</p>
              <div class="info-box spec">
                <div class="info-item" v-if="productDetail.minSupplyPrice || productDetail.maxSupplyPrice">
                  <span class="label">成本价</span>
                  <p class="value warn">
                    <span>{{productDetail.minSupplyPrice === productDetail.maxSupplyPrice || !productDetail.maxSupplyPrice ? `¥${productDetail.minSupplyPrice}` : `¥${productDetail.minSupplyPrice} - ¥${productDetail.maxSupplyPrice}`}}</span>
                  </p>
                </div>
                <div class="info-item">
                  <span class="label">供货价</span>
                  <p class="value">
                    <span>{{productDetail.minTradePrice === productDetail.maxTradePrice || !productDetail.maxTradePrice ? `¥${productDetail.minTradePrice}` : `¥${productDetail.minTradePrice} - ¥${productDetail.maxTradePrice}`}}</span>
                  </p>
                </div>
                <div class="info-item">
                  <span class="label">零售价</span>
                  <span class="value">{{productDetail.minPrice === productDetail.maxPrice || !productDetail.maxPrice ? `¥${productDetail.minPrice}` : `¥${productDetail.minPrice} - ¥${productDetail.maxPrice}`}}</span>
                </div>
                <div class="info-item">
                  <span class="label">划线价</span>
                  <span class="value">{{productDetail.minOriginalPrice === productDetail.maxOriginalPrice || !productDetail.maxOriginalPrice ? `¥${productDetail.minOriginalPrice}` : `¥${productDetail.minOriginalPrice} - ¥${productDetail.maxOriginalPrice}`}}</span>
                </div>
              </div>
              <div class="info-box" v-if="productDetail.specList.length">
                <div class="info-item">
                  <span class="label">规格</span>
                  <div class="value">
                    <p v-for="(item, i) in productDetail.skuList" :key="i" style="margin-bottom: 2px">
                      <span>{{item.skuName}}</span>
                      <span>￥{{item.price}}</span>
                      <span>{{item.stock}}可售</span>
                    </p>
                  </div>
                </div>
              </div>
              <p class="other-info">
                <span>起售：{{productDetail.productGeneral.startNum}}</span>
                <span>总库存：{{productDetail.stock}}</span><br />
                <span>类目：{{productDetail.categoryNames}}</span><br />
                <span>产地：{{productDetail.productGeneral.origin}}</span>
              </p>
            </div>
          </div>
          <div class="sub-title">商品详情</div>
          <div class="rich-text" v-if="productDetail.productDetail.detail" v-html="productDetail.productDetail.detail"></div>
          <div v-else class="custom-empty">
            <a-empty />
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { goods } from '@/api'
import NP from 'number-precision'

export default {
  name: 'PageGoodsDetail',
  props: {
    productId: String
  },

  setup (props, { root, emit }) {
    const loading = ref(false)
    const productDetail = ref({
      picList: [],
      specList: [],
      product: {},
      productDetail: {},
      productGeneral: {},
      minTradePrice: '',
      maxTradePrice: '',
      minSupplyPrice: '',
      maxSupplyPrice: '',
      minPrice: '',
      maxPrice: '',
      minOriginalPrice: '',
      maxOriginalPrice: '',
      stock: 0,
      categoryNames: ''
    })
    const picActive = ref(0)
    async function getDetail () {
      loading.value = true
      const { code, msg, data } = await goods.getProductDetail(props.productId)
      loading.value = false
      if (code === '00000') {
        if (data) {
          const { picList, skuList, productCategory } = data
          productDetail.value = {
            ...data,
            ...getPrices(data),
            picList: picList,
            stock: skuList.length
              ? skuList.map((x) => x.stock).reduce((total, current) => NP.plus(current, total))
              : skuList[0].stock,
            categoryNames:
              productCategory && productCategory.names ? productCategory.names.join(' > ') : ''
          }
        }
      } else {
        root.$message.error(msg || '详情获取失败，请稍后重试')
      }
    }
    getDetail()

    function getPrices (data) {
      const { specList, skuList } = data
      const hasSku = specList.length
      const priceVariables = [
        'tradePrice',
        'supplyPrice',
        'price',
        'originalPrice'
      ]
      let prices = {}
      priceVariables.forEach((item) => {
        const [i, ...last] = item
        const variable = `${i.toUpperCase()}${last.join('')}`
        if (hasSku) {
          const [min, max] = getSection(skuList, item)
          prices[`min${variable}`] = min
          prices[`max${variable}`] = max
        } else {
          prices[`min${variable}`] = skuList[0].hasOwnProperty(item) ? skuList[0][item] : null
        }
      })
      console.log(prices)
      return prices
    }

    function getSection (skuList, variable) {
      if (skuList.length > 1) {
        const arr = skuList.map((x) => x[variable] ? x[variable] : null).sort()
        return [arr[0], arr[arr.length - 1]]
      } else {
        return [skuList[0][variable], '']
      }
    }

    const swiperRef = ref(null)
    const swiperOptions = ref({
      slidesPerView: 4,
      spaceBetween: 10
    })
    function swiperControl (type) {
      if (type === 'prev') {
        swiperRef.value.$swiper.slidePrev()
      } else {
        swiperRef.value.$swiper.slideNext()
      }
    }

    return {
      baseUrl: process.env.VUE_APP_IMG,
      loading,
      productDetail,
      picActive,
      swiperRef,
      swiperOptions,
      swiperControl
    }
  }
}
</script>

<style lang="less" scoped>
.goods-detail-box {
  width: 830px;
  margin: 0 auto;
  min-height: calc(100vh - 180px);
}
.goods-info {
  display: flex;
  justify-content: space-between;
  .img-info {
    flex: none;
    width: 240px;
    .img-swiper {
      margin: 10px 0;
      ::v-deep .swiper-slide {
        padding: 2px;
        border: 1px solid #fff;
        cursor: pointer;
        &:hover,
        &.active {
          border-color: #eee;
        }
      }
      .swiper-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
        cursor: pointer;
        font-size: 13px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
      .btn-prev {
        left: 0;
      }
      .btn-next {
        right: 0;
      }
    }
  }
  .info-info {
    width: 374px;
    .goods-name {
      min-height: 44px;
      font-size: 14px;
      line-height: 22px;
      color: #333;
    }
    .sub-name {
      margin-bottom: 12px;
      font-size: 12px;
      color: #999;
    }
    .info-box {
      max-height: 200px;
      padding: 10px 10px 10px 0;
      border-bottom: 1px solid #dcdfe6;
      font-size: 12px;
      color: #666;
      line-height: 20px;
      overflow-y: auto;
      &.spec {
        background-color: #f3f6f8;
      }
      .warn {
        color: #e83828;
      }
      .info-item {
        display: flex;
        .label {
          flex: none;
          width: 68px;
          margin-right: 22px;
          text-align: right;
        }
        .value span {
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }
        .icon-tax {
          font-size: 14px;
          .warn;
        }
      }
    }
    .other-info {
      padding: 4px 0;
      font-size: 12px;
      line-height: 20px;
      span {
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .btn-box {
      display: flex;
      margin-top: 30px;
      ::v-deep .ant-btn {
        margin-right: 6px;
        &:last-child {
          margin-right: 0;
        }
      }
      .icon-table-tip {
        color: #cacaca;
      }
    }
  }
}
.sub-title {
  margin-top: 26px;
  padding: 0 16px;
  font-size: 16px;
  color: #333;
  line-height: 48px;
  background-color: #f3f6f8;
}
.rich-text {
  margin-top: 10px;
  ::v-deep img {
    max-width: 100%;
  }
}
.custom-empty {
  padding: 24px 0;
  ::v-deep .ant-empty-image svg {
    width: auto;
  }
}
.big-video {
  width: 240px;
  height: 240px;
  object-fit: contain;
}
.small-video {
  width: 46px;
  height: 46px;
  object-fit: contain;
}
</style>
